export function getSupportedFileTypes() {
  const maxTotalSize = 50;
  const maxFileSizeForMedia = 25;

  const typesInfo = [
    {
      name: "Text",
      extensions: "PDF, DOCX, XLSX, PPTX, CSV, EPUB, TXT, MD",
      note: "",
    },
    {
      name: "Audio",
      extensions: "MP3, WAV, M4A, MPGA",
      note: `Up to ${maxFileSizeForMedia} MB per file`,
    },
    {
      name: "Video",
      extensions: "MP4, MPEG, WEBM",
      note: `Up to ${maxFileSizeForMedia} MB per file`,
    },
  ];

  const textExtensions = typesInfo
    .find(t => t.name === "Text")
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const audioExtensions = typesInfo
    .find(t => t.name === "Audio")
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const videoExtensions = typesInfo
    .find(t => t.name === "Video")
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const allExtensions = typesInfo
    .map(t => t.extensions.split(", "))
    .flat()
    .map(e => `.${e.toLowerCase()}`);

  return { typesInfo, allExtensions, audioExtensions, videoExtensions, textExtensions, maxTotalSize, maxFileSizeForMedia };
}

export function removeFileExtension(text: string) {
  return text.replace(/\.[^/.]+$/, "");
}

export function getFileExtension(text: string, withDot = false) {
  let extension = text.split(".").pop();

  if (!extension)
    throw createError("No file extension found");

  extension = extension.toLowerCase();

  return withDot ? `.${extension}` : extension;
}

export function downloadAsCsvFile(fileNameWithoutExtension: string, data: any[]) {
  if (!data.length)
    return "";

  // Get the keys from the first object in the data array to use as headers
  const headers = Object.keys(data[0]).join(",");

  // Convert the data to CSV format
  const csvData = `${headers}\n${data.map(obj => Object.values(obj).join(",")).join("\n")}`;

  downloadStringAsFile(`${fileNameWithoutExtension}.csv`, csvData);
}

export function downloadStringAsFile(fileNameWithExtension: string, data: string) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileNameWithExtension;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function base64ToFile(filename: string, base64String: string, fileType?: string): void {
  let mimeType = "application/octet-stream";

  switch (fileType) {
    case "pdf":
      mimeType = "application/pdf";
      break;
    case "txt":
      mimeType = "text/plain";
      break;
    case "docx":
      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    case "epub":
      mimeType = "application/epub+zip";
      break;
    case "pptx":
      mimeType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      break;
    case "xlsx":
      mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    default:
      mimeType = "application/octet-stream";
  }

  const linkSource = `data:${mimeType};base64,${base64String}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

export function convertBytesToMB(bytes: number): number {
  const mb: number = bytes / 1048576;
  return Math.round(mb * 10) / 10; // Round to 1 decimal place
}
